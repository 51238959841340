
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { TilePlan } from '@/definitions/admin/monthlyPlans/types'
import Tile from '@/components/pages/admin/plans/Tile.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmButton,
    Tile,
  },
  props: {
    tile: {
      type: Object as PropType<TilePlan>,
      required: true,
    },
    price: {
      type: String,
    },
    textPer: {
      type: String,
    },
    totalMessagesShort: {
      type: String,
    },
  },
})
