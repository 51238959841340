import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "plan-table-row" }
const _hoisted_2 = { class: "d-flex align-center" }
const _hoisted_3 = {
  key: 0,
  class: "plan-table-row__subtitle distinct--text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.checkIcon)
        ? (_openBlock(), _createBlock(_component_tm_icon, {
            key: 0,
            name: "check_circle_outline",
            size: "large",
            class: "success--text mr-1"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["plan-table-row__title", {'font-weight-semi-bold': _ctx.boldTitle}])
      }, _toDisplayString(_ctx.title), 3)
    ]),
    (_ctx.subtitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.subtitle), 1))
      : _createCommentVNode("", true)
  ]))
}