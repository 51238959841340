import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!

  return (_openBlock(), _createBlock(_component_tm_field, {
    "model-value": _ctx.modelValue?.value,
    type: "select",
    options: _ctx.plansOptionsWithText,
    clearable: false,
    class: "plans-table-header__right-select",
    size: "medium",
    "option-label": "label",
    "option-value": "value",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:model-value', $event.value)))
  }, {
    "selected-item": _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.modelValue?.label) + " messages ", 1)
    ]),
    _: 1
  }, 8, ["model-value", "options"]))
}