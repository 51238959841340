import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4644b117"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-center mb-3" }
const _hoisted_2 = { class: "plan-tile__title" }
const _hoisted_3 = { class: "plan-tile__content" }
const _hoisted_4 = { class: "plan-tile__price" }
const _hoisted_5 = { class: "plan-tile__price-per" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tile = _resolveComponent("tile")!

  return (_openBlock(), _createBlock(_component_tile, {
    class: "plan-tile",
    color: _ctx.tile.color
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.tile.title) + " " + _toDisplayString(_ctx.totalMessagesShort), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, "$" + _toDisplayString(_ctx.price), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.textPer), 1)
      ]),
      (_ctx.tile?.button)
        ? (_openBlock(), _createBlock(_component_tm_button, {
            key: 0,
            color: _ctx.tile.button?.color,
            class: "plan-tile__btn"
          }, {
            default: _withCtx(() => [
              (_ctx.tile.button?.icon)
                ? (_openBlock(), _createBlock(_component_tm_icon, {
                    key: 0,
                    name: _ctx.tile.button.icon,
                    size: "small",
                    left: ""
                  }, null, 8, ["name"]))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(_ctx.tile.button.text), 1)
            ]),
            _: 1
          }, 8, ["color"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["color"]))
}