
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { TileColor } from '@/definitions/admin/monthlyPlans/types'
import TmChip from '@/components/shared/TmChip.vue'

export default defineComponent({
  components: { TmChip },
  props: {
    color: {
      type: String as PropType<TileColor>,
    },
    size: {
      type: String as PropType<'small' | ''>,
    },
    chipText: {
      type: String,
    },
  },
})
