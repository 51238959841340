
import { defineComponent } from 'vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  name: 'PlansTooltip',
  components: { TmTooltip },
  setup() {
    const { isSmMax } = useBreakpoints()

    return {
      isSmMax,
    }
  },
})
