import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_slider = _resolveComponent("q-slider")!

  return (_openBlock(), _createBlock(_component_q_slider, {
    "model-value": _ctx.modelValue,
    class: "tm-range-slider",
    color: _ctx.rgbToHex(_ctx.styleVars.blue500),
    "track-color": "white",
    "inner-track-color": "transparent",
    "track-size": "16px",
    "thumb-color": "blue",
    "thumb-size": "28px",
    snap: "",
    min: _ctx.min,
    max: _ctx.max,
    "inner-min": _ctx.innerMin,
    "inner-max": _ctx.innerMax,
    dense: "",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
  }, null, 8, ["model-value", "color", "min", "max", "inner-min", "inner-max"]))
}