import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19d847a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tile-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_chip = _resolveComponent("tm-chip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tile", {
      [`tile--${_ctx.color}`]: _ctx.color,
    }])
  }, [
    (_ctx.chipText)
      ? (_openBlock(), _createBlock(_component_tm_chip, {
          key: 0,
          color: _ctx.color,
          class: _normalizeClass(["tile__chip", [`tile__chip--${_ctx.color}`]])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.chipText), 1)
          ]),
          _: 1
        }, 8, ["color", "class"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 2))
}