import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "emphasize--text body-text-regular-14 py-3 px-2 lh-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!

  return (_openBlock(), _createBlock(_component_tm_tooltip, {
    white: "",
    "delay-time": 200,
    position: _ctx.isSmMax ? 'top-right' : 'right',
    "max-width": _ctx.isSmMax ? '270px' : '320px'
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }, 8, ["position", "max-width"]))
}