
import { computed, defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import PlanTile from '@/components/pages/admin/plans/PlanTile.vue'
import { tiles, tilesTariffsMap, tarifsOptions } from '@/definitions/admin/monthlyPlans/data'
import PlansTable from '@/components/pages/admin/plans/PlansTable.vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import PlansSelect from '@/components/pages/admin/plans/PlansSelect.vue'
import TmBreadcrumbs from '@/components/shared/responsive/breadcrumbs/TmBreadcrumbs.vue'
import TmRangeSlider from '@/components/shared/TmRangeSlider.vue'

export default defineComponent({
  components: {
    TmRangeSlider,
    TmBreadcrumbs,
    PlansSelect,
    PlansTable,
    PlanTile,
    PageContent,
  },
  setup() {
    const { isSmMax, isMdMax } = useBreakpoints()
    const sliderValue = ref(2)
    const currentValue = computed(
      () => tarifsOptions.find(item => item.value === sliderValue.value?.toString()) || tarifsOptions[1]
    )

    const breadcrumbs = [
      { label: 'Admin', name: 'base.admin' },
      { label: 'Plans' },
    ]

    return {
      tiles,
      sliderValue,
      tarifsOptions,
      tilesTariffsMap,
      currentValue,
      isSmMax,
      isMdMax,
      breadcrumbs,
    }
  },
})
