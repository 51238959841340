
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import { tarifsOptions } from '@/definitions/admin/monthlyPlans/data'
import type { PlanVariant } from '@/definitions/admin/monthlyPlans/types'

const plansOptionsWithText = tarifsOptions.map(item => ({
  ...item,
  label: item.label + ' messages',
}))

export default defineComponent({
  props: {
    modelValue: {
      type: Object as PropType<PlanVariant>,
    },
  },
  emits: ['update:model-value'],
  setup() {
    return {
      plansOptionsWithText,
    }
  },
})
