import type {
  PlanVariant,
  TilePlansList, TileTariffList
} from '@/definitions/admin/monthlyPlans/types'

export const tiles: TilePlansList = {
  essential: {
    color: 'green',
    title: 'Essential',
    subtitle: [
      'Simple ',
      {
        text: 'pay-as-you-go',
        style: 'bold',
      },
      ' model. Add credit and spend it whenever you choose.',
    ],
    button: {
      text: 'Buy credit',
      icon: 'shopping_cart',
    },
    items: [
      {
        text: 'Add credit and pay per text',
        tooltip: (count: string, price = 49) => `With this plan, you can add credit and spend it when needed on sending messages and other services. The cost of one text message part is always $0.049. \n\n ${count} messages will cost $${price}.\n\n 1 message part is 160 plain text characters or 70 Unicode characters long.`,
        highlight: true,
        icon: 'check_circle_outline',
      },
      {
        text: 'Credit never expires',
        tooltip: 'Any purchased credits remain valid indefinitely without an expiration date.',
        highlight: false,
        icon: 'check_circle_outline',
      },
      {
        text: 'Access to all features',
        tooltip: [
          'You have full access to all available features of the Textmagic platform, such as scheduling messages, contact management, ',
          {
            text: 'and more',
            style: 'semi-bold',
            link: '#',
          },
          '.',
        ],
        highlight: false,
        icon: 'check_circle_outline',
      },
      {
        text: 'Unlimited users',
        tooltip: 'Allows you to add as many users to your account as needed without extra charges.',
        highlight: false,
        icon: 'check_circle_outline',
      },
      {
        text: 'Virtual number for extra fee',
        tooltip: 'To start texting you must buy a virtual dedicated number for an extra fee of $10 / month.',
        highlight: false,
        icon: 'highlight_off',
      },
      {
        text: 'Inbound texts for extra fee',
        tooltip: 'Receiving incoming text messages will incur an additional charge of $0.02 per message.',
        highlight: false,
        icon: 'highlight_off',
      },
    ],
    chipText: 'Current plan',
  },
  professional: {
    color: 'beta',
    title: 'Professional',
    subtitle: [
      {
        text: 'Monthly plan',
        style: 'bold',
      },
      ' with a volume discount. Enjoy cheaper costs per text and monthly billing.',
    ],
    button: {
      color: 'primary',
      text: 'Select plan',
    },
    badgeColor: 'green',
    items: [
      {
        text: (arg: string) => `${arg} monthly texts included`,
        tooltip: (arg: string) => `This plan includes a monthly prepaid credit for sending ${arg} text messages. If you exceed plan limit, it will trigger a renewal of the monthly plan.\n\n1 message part is 160 plain text characters or 70 Unicode characters long.`,
        highlight: true,
        icon: 'check_circle_outline',
      },
      {
        text: 'Credit rolls over up to 2 months',
        tooltip: [
          'The unused credit balance remains active for up to 2 months from the date of purchase.\n\n',
          {
            text: 'Example:',
            style: 'bold',
          },
          ' Unused credit bought on the 15th of January will expire on the 1st of March (after 1.5 months). Unused credit bought on the 1st of March will expire on the 1st of May (after 2 months).',
        ],
        highlight: false,
        icon: 'check_circle_outline',
      },
      {
        text: 'Access to all features',
        tooltip: [
          'You have full access to all available features of the Textmagic platform, such as scheduling messages, contact management, ',
          {
            text: 'and more',
            style: 'semi-bold',
            link: '#',
          },
          '.',
        ],
        highlight: false,
        icon: 'check_circle_outline',
      },
      {
        text: 'Unlimited users',
        tooltip: 'Allows you to add as many users to your account as needed without extra charges.',
        highlight: false,
        icon: 'check_circle_outline',
      },
      {
        text: 'Virtual number included',
        tooltip: 'This plan includes one virtual number at no additional cost.',
        highlight: false,
        icon: 'check_circle_outline',
      },
      {
        text: 'Inbound texts are free',
        tooltip: 'Receiving incoming text messages is included in the plan at no extra cost.',
        highlight: false,
        icon: 'check_circle_outline',
      },
    ],
    chipText: 'Most popular',
  },
  connect: {
    color: 'orange',
    title: 'Connect',
    subtitle: [
      {
        text: 'Connect your CPaaS provider',
        style: 'bold',
      },
      ' (e.g. Twilio) to Textmagic and enjoy low texting costs.',
    ],
    button: {
      color: 'primary',
      text: 'Select plan',
    },
    badgeColor: 'orange',
    items: [
      {
        text: (arg: string) => `${arg} monthly texts included`,
        tooltip: (arg: string) => `This plan includes a monthly prepaid credit for sending ${arg} text messages via a CPaaS provider integration. If you exceed plan limit, it will trigger a renewal of the monthly plan.\n\nStandard fees apply for using other Textmagic services.`,
        highlight: true,
        icon: 'check_circle_outline',
      },
      {
        text: 'Credit rolls over up to 2 months',
        tooltip: [
          'The unused credit balance remains active for up to 2 months from the date of purchase.\n\n',
          {
            text: 'Example:',
            style: 'bold',
          },
          ' Unused credit bought on the 15th of January will expire on the 1st of March (after 1.5 months). Unused credit bought on the 1st of March will expire on the 1st of May (after 2 months).',
        ],
        highlight: false,
        icon: 'check_circle_outline',
      },
      {
        text: 'Access to all features',
        tooltip: [
          'You have full access to all available features of the Textmagic platform, such as scheduling messages, contact management, ',
          {
            text: 'and more',
            style: 'semi-bold',
            link: '#',
          },
          '.',
        ],
        highlight: false,
        icon: 'check_circle_outline',
      },
      {
        text: 'Unlimited users',
        tooltip: 'Allows you to add as many users to your account as needed without extra charges.',
        highlight: false,
        icon: 'check_circle_outline',
      },
      {
        text: [
          'Virtual numbers ',
          {
            text: 'from CPaaS',
            style: 'semi-bold',
          },
        ],
        tooltip: 'Your existing virtual numbers will be connected via API from your CPaaS provider at no extra cost.',
        highlight: false,
        icon: 'check_circle_outline',
      },
      {
        text: 'Inbound texts are free',
        tooltip: 'Receiving incoming text messages is included in the plan at no extra cost.',
        highlight: false,
        icon: 'check_circle_outline',
      },
    ],
  },
}

export const tarifsOptions: PlanVariant[] = [
  {
    labelSlider: '500 texts',
    labelShort: '500',
    label: '500',
    labelExtraShort: '500',
    value: '1',
  },
  {
    label: '1,000',
    labelShort: '1,000',
    labelExtraShort: '1K',
    value: '2',
  },
  {
    label: '2,000',
    labelShort: '2,000',
    labelExtraShort: '2K',
    value: '3',
  },
  {
    label: '5,000',
    labelShort: '5,000',
    labelExtraShort: '5K',
    value: '4',
  },
  {
    label: '10,000',
    labelShort: '10,000',
    labelExtraShort: '10K',
    value: '5',
  },
  {
    label: '20,000',
    labelShort: '20,000',
    labelExtraShort: '20K',
    value: '6',
  },
  {
    label: '50,000',
    labelShort: '50K',
    labelExtraShort: '50K',
    value: '7',
  },
  {
    label: '100,000',
    labelShort: '100K',
    labelExtraShort: '100K',
    value: '8',
  },
  {
    label: '150,000',
    labelShort: '150K',
    labelExtraShort: '150K',
    value: '9',
  },
  {
    label: '200,000',
    labelShort: '200K',
    labelExtraShort: '200K',
    value: '10',
  },
]

export const tilesTariffsMap: TileTariffList = {
  essential: {
    1: {
      perMessage: '0.049',
      price: '25',
    },
    2: {
      perMessage: '0.049',
      price: '49',
    },
    3: {
      perMessage: '0.049',
      price: '98',
    },
    4: {
      perMessage: '0.049',
      price: '245',
    },
    5: {
      perMessage: '0.049',
      price: '490',
    },
    6: {
      perMessage: '0.049',
      price: '980',
    },
    7: {
      perMessage: '0.049',
      price: '2,450',
    },
    8: {
      perMessage: '0.049',
      price: '4,900',
    },
    9: {
      perMessage: '0.049',
      price: '7,350',
    },
    10: {
      perMessage: '0.049',
      price: '9,800',
    },
  },
  professional: {
    1: {
      perMessage: '0.044',
      price: '22',
      discount: '10',
    },
    2: {
      perMessage: '0.042',
      price: '42',
      discount: '14',
    },
    3: {
      perMessage: '0.040',
      price: '80',
      discount: '18',
    },
    4: {
      perMessage: '0.038',
      price: '190',
      discount: '22',
    },
    5: {
      perMessage: '0.036',
      price: '360',
      discount: '27',
    },
    6: {
      perMessage: '0.034',
      price: '680',
      discount: '31',
    },
    7: {
      perMessage: '0.032',
      price: '1,600',
      discount: '35',
    },
    8: {
      perMessage: '0.030',
      price: '3,000',
      discount: '39',
    },
    9: {
      perMessage: '0.028',
      price: '4,200',
      discount: '43',
    },
    10: {
      perMessage: '0.025',
      price: '5,000',
      discount: '49',
    },
  },
  connect: {
    1: {
      perMessage: '0.01',
      price: '5',
    },
    2: {
      perMessage: '0.01',
      price: '10',
    },
    3: {
      perMessage: '0.01',
      price: '20',
    },
    4: {
      perMessage: '0.01',
      price: '50',
    },
    5: {
      perMessage: '0.01',
      price: '100',
    },
    6: {
      perMessage: '0.01',
      price: '200',
    },
    7: {
      perMessage: '0.01',
      price: '500',
    },
    8: {
      perMessage: '0.01',
      price: '1,000',
    },
    9: {
      perMessage: '0.01',
      price: '1,500',
    },
    10: {
      perMessage: '0.01',
      price: '2,000',
    },
  },
}
