
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { PlanVariant, TilePlan } from '@/definitions/admin/monthlyPlans/types'
import Tile from '@/components/pages/admin/plans/Tile.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmChip from '@/components/shared/TmChip.vue'
import TmStylizedText from '@/components/shared/TmStylizedText.vue'
import PlansTooltip from '@/components/pages/admin/plans/PlansTooltip.vue'

export default defineComponent({
  components: {
    PlansTooltip,
    TmStylizedText,
    TmChip,
    TmButton,
    Tile,
  },
  props: {
    tile: {
      type: Object as PropType<TilePlan>,
      required: true,
    },
    price: {
      type: String,
    },
    pricePerMessage: {
      type: String,
    },
    textPer: {
      type: String,
      default: 'per month',
    },
    discount: {
      type: [Number, String],
    },
    currentValue: {
      type: Object as PropType<PlanVariant>,
    },
    totalMessagesShort: {
      type: String,
    },
  },
  setup() {
    const isFunction = (functionToCheck: any) => {
      return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
    }

    return {
      isFunction,
    }
  },
})
