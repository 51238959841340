
import type { PropType } from 'vue'
import {
  defineComponent
} from 'vue'
import PlanTableTile from '@/components/pages/admin/plans/PlanTableTile.vue'
import { tiles, tilesTariffsMap } from '@/definitions/admin/monthlyPlans/data'
import TmButton from '@/components/shared/TmButton.vue'
import PlanTableRow from '@/components/pages/admin/plans/PlanTableRow.vue'
import PlansTooltip from '@/components/pages/admin/plans/PlansTooltip.vue'
import { useModals } from '@/compositions/modals'
import type { PlanVariant } from '@/definitions/admin/monthlyPlans/types'
import PlansSelect from '@/components/pages/admin/plans/PlansSelect.vue'
import TmScrollbarXShadows from '@/components/shared/tmScrollbar/TmScrollbarXShadows.vue'

export default defineComponent({
  components: { TmScrollbarXShadows, PlansSelect, PlansTooltip, PlanTableRow, TmButton, PlanTableTile },
  props: {
    currentValue: {
      type: Object as PropType<PlanVariant>,
      required: true,
    },
  },
  emits: ['update:model-value'],
  setup() {
    const { openModal } = useModals()
    return {
      tiles,
      tilesTariffsMap,
      openModal,
    }
  },
})
