
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PlanTableRow',
  props: {
    title: {
      type: String,
    },
    boldTitle: {
      type: Boolean,
    },
    subtitle: {
      type: String,
    },
    checkIcon: {
      type: Boolean,
      default: true,
    },
  },
})
