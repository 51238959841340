
import { defineComponent } from 'vue'
import { rgbToHex, styleVars } from '@/compositions/styleVariables'

export default defineComponent({
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 11,
    },
    innerMin: {
      type: Number,
      default: 1,
    },
    innerMax: {
      type: Number,
      default: 10,
    },
  },
  emits: ['update:modelValue'],
  setup() {
    return {
      rgbToHex,
      styleVars,
    }
  },
})
